/* TODO: RT - This stuff is probably dead but leaving it here for now, we will not force an aspect ratio */

.width-adjusting-to-height {
  display: inline-block;
  height: 80vh;
  position: relative;
}

.width-adjusting-to-height > svg {
  height: 100%;
}

.width-adjusting-to-height .calendar-container-4x3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  border-color: var(--ion-color-light-shade);
  border-style: double;
}
